import React, { FC } from "react"
// import { Link } from "gatsby"
import Head from "../../components/head"
import Header from "../../components/header"
import Footer from "../../components/footer"
import styles from "../../styles/global.css"
import contact from "../../styles/media_page.css"
import media from "../../styles/media.css"

export default () => (
  <>
    <Header titleText="Media - Happy Mango" />
    <div className="main">
      <div className="PC_head">
        <div className="logo_div">
          <a rel="" href="/" className="logo" title="Happy Mango">
            <img
              className="light"
              src="/images/hm-logo-v2.png"
              alt="Happy Mango"
            />
          </a>
        </div>
        <ul className="nav_ul">
          <li className="nav_li">
            <a rel="" href="/services/">
              Our Services
            </a>
            <ul className="nav_ul_son">
              <li className="nav_li_son">
                <a rel="" href="/services/#Digitalize">
                  Front to Back Digitalization
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Customize">
                  Customized Underwriting
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Expand">
                  Digital Marketing Tools
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Improve">
                  Financial Health Management
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/services/#Track">
                  Impact Analysis and Reporting
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/clients/">
              Our Clients
            </a>
            <ul className="nav_ul_son nav_ul_son_se">
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Banks
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  Credit Unions
                </a>
              </li>
              <li className="nav_li_son">
                <a rel="" href="/clients/#clients_top">
                  CDFIs
                </a>
              </li>
            </ul>
          </li>
          <li className="nav_li">
            <a rel="" href="/why/">
              Why Happy Mango?
            </a>
          </li>
        </ul>
        <div className="clear_both"></div>
        <div className="loginheader_div">
          <div className="loginheader">
            <div className="loginheaderlist_div">
              <img
                alt="icon doller"
                src="/sites/default/files/icon_doller.png"
              />
              <a rel="" href="/pricing/">
                Pricing
              </a>
            </div>
            <div className="loginheaderlist_div">
              <img alt="icon trial" src="/sites/default/files/icon_trial.png" />
              <a
                rel=""
                href="https://institution.happymangocredit.com/register/banker?sRegisterType=0"
                target="_blank"
              >
                Free Trial
              </a>
            </div>

            <div className="loginheaderlist_div">
              <img alt="user" src="/sites/default/files/user.png" />
              <span className="login_span">
                <a rel="" href="javascript:;">
                  Log In
                </a>
                <ul className="login_ul_son">
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://user.happymangocredit.com"
                      target="_blank"
                    >
                      Individual/Business
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://institution.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Institutions
                    </a>
                  </li>
                  <li className="login_li_son">
                    <a
                      rel=""
                      href="https://advisor.happymangocredit.com"
                      target="_blank"
                    >
                      Financial Coaches
                    </a>
                  </li>
                </ul>
              </span>
            </div>
          </div>
        </div>
      </div>

      <Head />

      {/* <div className="banner_contact">
        <img
          className="contact_img"
          src="/images/fresh-leaves.jpg"
          alt="banner"
        />
        <h1 className="bannerinfo">Mango Slice Blog</h1>
      </div> */}

      <div className="media">
      

      <div id="preface" className="block">
        <div className="region region-preface">
          <div id="block-views-security-block" className="block block-views blue we-believe">
            <div className="inner">
              <div className="content">
                <div className="view view-security view-id-security view-display-id-block view-dom-id-09b08ef9d2ef556de55f0586d9ed9143">
                  <div className="view-content">
                    <div className="item">
                      <div>
                        <div className="title">
                          <div
                            style={{
                              "margin-top": "-10px",
                              "text-align": "left",
                              "font-size": "28px",
                              "font-weight": "bold",
                            }}
                          >
                            <h3>Middle-Income Consumers Demand Small-Dollar Loans</h3>
                          </div>
                          <div>
                            <div>
                              <div
                                className="excerpt"
                                style={{
                                  display: "block",
                                  "text-align": "right",
                                  "font-size": "14px",
                                }}
                              >
                                <img src="/sites/all/themes/hmm/images/blogs/date-gray.png" 
                                  style={{width: '16px'}}
                                /> Sept 30, 2016
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <img src="/sites/all/themes/hmm/images/blogs/3.jpg"
                        style={{
                          width: "100%",
                          "margin-top": "20px",
                          "margin-bottom": "20px",
                        }}
                      />
                      <div className="sharethis-inline-share-buttons"></div>
                      <div className="blog_desc_section">
                        <p>Since we made small-dollar consumer loans available on our platform this past April, we have received hundreds of applications. Statistics of our user data indicate that middle-income consumers prefer small-dollar loans for managing short-term cash flow volatility because they are easy-to-manage and easy-to-control.&nbsp;</p>
                        <h3>
                          <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;77% of applicants have verified annual income of above $30,000 </strong>
                        </h3>
                        <p>The US Census Bureau reported a <a href="http://www.census.gov/data/tables/time-series/demo/income-poverty/cps-pinc/pinc-01.html" target="_blank" rel="nofollow noopener">2015 median personal income of $30,240</a> for all who worked. That puts most of our loan applicants in the middle-income wage earners’ group. </p>
                        <p>The diagram below shows the income distribution for the overall income generating population as well as the income distribution for our loan applicants. With <a href="http://www.creditcards.com/credit-card-news/ownership-statistics-charts-1276.php" target="_blank" rel="nofollow noopener">167million Americans holding at least one credit card</a>, middle-income wage earners in general should have access to credit cards. However, to cover short-term liquidity need, many seem to prefer taking out an installment loan with fixed term to an open-ended credit line. </p>
                        <div className="slate-resizable-image-embed slate-image-embed__resize-full-width">
                          <img
                              style={{ width: "100%" }}
                              data-media-urn="" data-li-src="https://media.licdn.com/dms/image/C4E12AQEN1xe9FcCddg/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=USFiclOQ55XnbVt-n7DYsU8ygDwzBM14pcoLhkOL16E" src="https://media.licdn.com/dms/image/C4E12AQEN1xe9FcCddg/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=USFiclOQ55XnbVt-n7DYsU8ygDwzBM14pcoLhkOL16E"
                          />
                        </div>
                        <h3>
                          <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;95% applicants have a full-time job, and 85% of them are above 30 years old</strong>
                        </h3>
                        <p>Most of our applicants are mature both in age and in job history.&nbsp;Their median age is 38 and average work experience is above five years. Almost all are generating current income. Some even have multiple sources of income. </p>
                        <p>Individuals in <a href="http://www.web-books.com/eLibrary/Books/B0/B66/IMG/fwk-collins-fig14_007.jpg" target="_blank" rel="nofollow noopener">this demographic group tend to be in the process of building a family or finishing advanced professional training</a>.&nbsp;Financially, they likely are responsible for others in addition to themselves, which means not only is there more demand on their income but also there are more variables in their spending pattern.&nbsp;Both could lead to cash flow volatility. </p>
                        <div className="slate-resizable-image-embed slate-image-embed__resize-full-width">
                          <img
                            style={{width: '100%'}}
                            data-media-urn="" data-li-src="https://media.licdn.com/dms/image/C4E12AQGivgbM3BImsw/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=2krNfakr-E9gNCOxUXrgcaCw8PFrXZzSDiqM15W9MKA" src="https://media.licdn.com/dms/image/C4E12AQGivgbM3BImsw/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=2krNfakr-E9gNCOxUXrgcaCw8PFrXZzSDiqM15W9MKA"
                          />
                        </div>
                        <h3>
                          <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;A wide range of use for loan proceeds, from emergency cash to paying bills</strong>
                        </h3>
                        <p>An analysis of the spending needs further confirms the above conclusion. &nbsp;There is hardly a dominant purpose in the use of borrowed proceeds.&nbsp;In addition to covering unexpected (e.g. medical, legal, repairs) and expected large spending (education, moving, wedding, vacation, purchase), as many as 20% of the applicants are borrowing just to pay bills or rents.&nbsp;&nbsp;&nbsp;</p>
                        <div className="slate-resizable-image-embed slate-image-embed__resize-full-width">
                          <img
                              style={{ width: '100%' }}
                              data-media-urn="" data-li-src="https://media.licdn.com/dms/image/C4E12AQEHjBjXe_QEmQ/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=0__52nkBTM3BHLdS9M0zcsAzbNm5ZYdEy2MtO342ryg" src="https://media.licdn.com/dms/image/C4E12AQEHjBjXe_QEmQ/article-inline_image-shrink_1500_2232/0?e=1550102400&amp;v=beta&amp;t=0__52nkBTM3BHLdS9M0zcsAzbNm5ZYdEy2MtO342ryg"
                          />
                        </div>
                        <p>In a <a href="http://www.brookings.edu/wp-content/uploads/2011/03/2011a_bpea_lusardi.pdf" target="_blank" rel="nofollow noopener">paper published in 2011 by the Brookings Institution</a>, researchers found that “one-quarter of U.S. households surveyed report that they are certain they could not come up with $2,000 within 30 days, and an additional 19 percent of all respondents would cope at least in part by selling or pawning possessions or taking payday loans.”&nbsp;More recently, according <a href="http://www.federalreserve.gov/2015-report-economic-well-being-us-households-201605.pdf" target="_blank" rel="nofollow noopener">to a survey by the Federal Reserve</a>, “Forty-six percent of adults say they either could not cover an emergency expense costing $400”, and “40 percent of those who desired credit say that they faced a real or perceived difficulty in accessing credit.”&nbsp; </p>
                        <p>Despite the strong demand for small-dollar consumer loans, high costs have kept many regulated financial institutions from offering this product. At <a href="http://www.happymangocredit.com" target="_blank" rel="nofollow noopener">Happy Mango</a>, we strive to substantially reduce the high underwriting and on-going management costs through data technology so that lenders can afford to make affordable loans to their customers. What is your institution’s consumer lending strategy?&nbsp; We would love to hear from you. </p>
                        <p>How we can help: <a href="https://calendly.com/kate-hao_happymango/happy-mango-30-minute-online-demo" className="reqbtn">Request Demo</a>
                        </p>
                        <p>&nbsp;</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  








      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  </>
)
